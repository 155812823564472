<template>
    <div class="app">
        <Header/>
        <!-- Content -->
        <div class="container" style="margin-top: 60px;">

            <div class="row">
                <div class="col">
                    <h1 style="color: #FE9800; font-weight: bold;">Contact Us</h1>
                    <hr style="border: 2px solid #0077E5;margin-left: -120px;width: 330px;">
                </div>
            </div>

            <div class="row mb-5">
                <div class="col-12 col-md-12 col-lg8">
                    <p>We would love to hear from you! Please fill out this form and we will get in touch with you shortly </p>
                </div>
            </div>

            <div class="container text-field mb-5">
                <div class="d-flex justify-content-center">
                    <div class="col-12 col-md-12 col-lg-8">
                        <div class="row">
                            <div class="col-12 col-md-12 col-lg-6 ">
                                <div class="form-group">
                                    <label for="nama">Nama<span class="badge badge-field" >*</span></label>
                                    <input type="text" class="form-control" id="nama">
                                </div>
                            </div>
                            <div class="col-12 col-md-12 col-lg-6">
                                <div class="form-group">
                                    <label for="email">Email<span class="badge badge-field" >*</span></label>
                                    <input type="email" class="form-control" id="email">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 col-md-12 col-lg-6">
                                <div class="form-group">
                                    <label for="exampleInputEmail1">Company<span class="badge badge-field" >*</span></label>
                                    <input type="text" class="form-control" id="exampleInputEmail1">
                                </div>
                            </div>
                            <div class="col-12 col-md-12 col-lg-6">
                                <div class="form-group">
                                    <label for="phone">Phone</label>
                                    <input type="text" class="form-control" id="phone">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 col-md-12 col-lg-12">
                                <div class="form-group">
                                    <label for="exampleInputEmail1">Message<span class="badge badge-field" >*</span></label>
                                    <textarea name="" id="" cols="30" rows="10" class="form-control"></textarea>
                                </div>
                            </div>
                        </div>
                        <button type="submit" class="btn" style="background-color: #FE9800;color: white; font-weight: bold;">Submit</button>
                    </div>
                </div>
            </div>

            <!-- <div class="row">
                <div class="col">
                    <hr style="border: 1px solid #0077E5;">
                </div>
            </div>

            <div class="row mb-5">
                <div class="col">
                    <h5 style="color: #FE9800;font-weight: bold;">Contact Details</h5>
                    <p>Phone : (62) 821-1003-2270<br>Instagram : @makutacommunication</p>
                </div>
            </div> -->


        </div>
        <!-- Footer -->
        <footer-page/>
    </div>
</template>

<script>
import FooterPage from '../components/FooterPage.vue'
import Header from '../components/Header.vue'
export default {
  components: { FooterPage, Header },
    name: 'Contact'
}
</script>